.ant-steps-item-description {
    color: #252525 !important;
    font-weight: 600;
}

.ant-steps-item-title {
    color: #848484 !important;
    font-weight: 400;
}

.ant-steps-icon {
    color: white !important;
    font-size: 1rem;
}

.ant-steps .ant-steps-item-icon .ant-steps-icon {
    position: relative;
    top: -0.5px;
    color: white !important;
    line-height: 1;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
    background-color: green !important;

}

.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
    background-color: #0FBC0C !important;
}

.ant-steps .ant-steps-item-tail::after {
    display: inline-block;
    width: 100%;
    height: 2px;
    background: rgba(5, 5, 5, 0.06);
    border-radius: 1px;
    transition: background 0.3s;
    content: "";
}

.ant-steps .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-tail::after {
    background-color: #204289;
}

/* 
.ant-steps .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-tail::after {
    background-color: #204289;
} */


.ant-steps .ant-steps-item-process .ant-steps-item-icon {
    background-color: rgba(0, 0, 0, 0.06) !important;
    border-color: white !important;
}

.ant-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-tail::after {
    background-color: #204289;
}