.loader-container {
    height: 100vh;
    width: 100%;
    position: fixed;
    background-color: rgba(255, 255, 255, 0.6);
    z-index: 9990;
    opacity: 0.5;
}

.loader-icon {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0);
}